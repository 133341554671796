<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Resources" size="large" />
    <img src="@/assets/homeslide4-min.jpg" />
    <div class="flex flex-wrap mt-8">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <TitleComponent title="Resources" />
        <PanelGroupComponent :panels="panels" />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) has decades of experience in
            the explosives field and has developed an extensive library of
            useful resources. Some of these resources are provided online. For
            any questions that you may have please feel free to give us a call
            or contact us through our website.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "Resources",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content: "SMS resources compiled from decades of experience."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    PanelGroupComponent
  },
  data: () => {
    return {
      panels: [
        {
          content1: `Click here for a list of useful`,
          contentLink: {
            text: "DOT Resources",
            to: "/resources/dot-links-and-resources"
          },
          title: "DOT Resources"
        },
        {
          content1: `Review our`,
          contentLink: {
            text: "guide for obtaining a shipping classification",
            to: "/resources/obtain-a-shipping-classification"
          },
          title: "How to Obtain a Shipping Classification"
        },
        {
          content1: `Description of`,
          contentLink: {
            text: "energetic material and their basic properties",
            to: "/resources/energetic-materials-101"
          },
          title: "Energetic Materials 101"
        },
        {
          content1: `List of`,
          contentLink: {
            text: "Technical Presentations",
            to: "/resources/technical-presentations"
          },
          content2: `given by SMS at various meeting and conferences.`,
          title: "Technical Presentations"
        },
        {
          content1: `List of`,
          contentLink: {
            text: "useful papers and documents",
            to: "/resources/document-library"
          },
          content2: `regarding explosive safety and testing.`,
          title: "Document Library"
        },
        {
          content1: `<p>The <a href="http://www.etusersgroup.org/">ET Users Group</a> exists to facilitate improving and standardizing in-process material response characterization methods. We meet annually to accomplish the objectives of the Charter given below.</p><br /><p>The <a href="http://www.etusersgroup.org/">ET Users Group</a> will collaborate to systematically minimize the variability associated with energetic materials testing to enable consistent/repeatable test data and interpretation of test results. This will be accomplished by:<br>
1) Developing procedures and methods<br>
2) Applying technologies<br>
3) Reaching consensus<br>
4) Performing periodic “Round Robin” test series on standard materials</p>`,
          title: "Explosives Testing Users Group"
        },
        {
          content1: `Description of the`,
          contentLink: {
            text: "explosive safety fundamentals",
            to:
              "/resources/energetic-materials-101/explosive-safety-fundamentals"
          },
          content2: `that are important to any energetic material operation`,
          title: "Explosive Safety Fundamentals"
        },
        {
          content1: `Explosives sensitivity testing`,
          contentLink: {
            text: "statistical evaluation",
            to: "/resources/statistical-evaluation"
          },
          title: "Statistical Evaluation"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.about {
}
</style>
